import React, { useMemo, useState, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
// import LineChart from '@/components/charts/LineChart'
import MapsIcons from '@/components/icons/maps'
import ObjectWidgetSettings from '@/forms/ObjectWidgetSettings'
import ManyObjectsWidgetSettings from '@/forms/ManyObjectsWidgetSettings'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { FormattedMessage as Lang } from 'react-intl'
import {
  MIN_VALUE,
  MAX_VALUE,
  MIN_CRITICAL_VALUE,
  MAX_CRITICAL_VALUE,
} from '@/constants/forms/objectWidgetSettings'
import {
  IndicatorWrapper,
  Value,
  IndicatorLine,
  IndicatorLineActive,
  Title,
  DescriptionWrapper,
  ValueBorder,
  ValueBorders,
  ControlsRow,
  IconContainer,
  Unit,
} from './styles'

const StateIndicator = ({
  id,
  value,
  data,
  minValue,
  maxValue,
  settingsControll,
  chartControll,
  units,
  title,
  type,
  className,
  settings,
  onUpdateSettings,
  status,
  percentValue,
}) => {
  // const [chartView, setChartView] = useState(false)
  const [manySettingsView, setManySettingsView] = useState(false)
  const [settingsView, setSettingsView] = useState(false)
  const handleOpenSettings = useCallback(
    () => setSettingsView(false),
    [setSettingsView],
  )
  const handleCloseSettings = useCallback(
    (event) => {
      if (event) {
        event.preventDefault()
      }
      setSettingsView(false)
    },
    [setSettingsView],
  )
  const handleOpenManySettings = useCallback(
    () => setManySettingsView(true),
    [setManySettingsView],
  )
  const handleCloseManySettings = useCallback(
    (event) => {
      if (event) {
        event.preventDefault()
      }
      setManySettingsView(false)
    },
    [setManySettingsView],
  )
  // const handleSetChartView = useCallback(
  //   () => setChartView(!chartView),
  //   [setChartView, chartView],
  // )

  const valueNumber = useMemo(() => {
    const currentValue = numberWithSpaces(Number.parseFloat(value || 0, 10).toFixed(3))
    return percentValue ? currentValue * 100 : currentValue
  }, [percentValue, value])
  const minValueNumber = useMemo(
    () => numberWithSpaces(Number.parseFloat(settings[MIN_VALUE] || minValue || 0, 10)),
    [minValue, settings],
  )
  const maxValueNumber = useMemo(
    () => numberWithSpaces(Number.parseFloat(settings[MAX_VALUE] || maxValue || 0, 10)),
    [maxValue, settings],
  )
  const minValueNumberByField = useCallback(
    (field) => numberWithSpaces(Number.parseFloat(settings[field] || minValue || 0, 10)),
    [minValue, settings],
  )
  const maxValueNumberByField = useCallback(
    (field) => numberWithSpaces(Number.parseFloat(settings[field] || maxValue || 0, 10)),
    [maxValue, settings],
  )
  const isCriticalManyMinValue = useCallback(
    (row) => {
      if (settings[`${MIN_CRITICAL_VALUE}${row.title}`]) {
        return true
      }
      return false
    },
    [settings],
  )
  const isCriticalManyMaxValue = useCallback(
    (row) => {
      if (settings[`${MAX_CRITICAL_VALUE}${row.title}`]) {
        return true
      }
      return false
    },
    [settings],
  )

  const calculateIndicatorWidth = useCallback((targetValue, valueMinBorder, valueMaxBorder) => {
    let targetValueNumber = Number.parseFloat(targetValue)
    if (valueMinBorder === 0 && valueMaxBorder === 0) {
      return 'withoutBorder'
    }
    if (targetValueNumber <= valueMinBorder) {
      return 0
    }
    if (targetValueNumber >= valueMaxBorder) {
      return 100
    }
    if (targetValueNumber > valueMinBorder && targetValueNumber < valueMaxBorder) {
      return (((valueNumber-minValueNumber)/(maxValueNumber-minValueNumber))*100).toFixed(0)
    }
  },
  [])
  const indicatorWidth = useMemo(
    () => calculateIndicatorWidth(
      valueNumber,
      minValueNumber,
      maxValueNumber,
    ),
    [calculateIndicatorWidth, valueNumber, minValueNumber, maxValueNumber],
  )

  const manyIndicatorsWidth = useCallback(
    (row) => {
      const valueMinBorder = minValueNumberByField(`${MIN_VALUE}${row.title}`)
      const valueMaxBorder = maxValueNumberByField(`${MAX_VALUE}${row.title}`)

      return calculateIndicatorWidth(row.value, valueMinBorder, valueMaxBorder)
    },
    [calculateIndicatorWidth, minValueNumberByField, maxValueNumberByField],
  )

  const uniqueKey = useMemo(() => (`${id}-${indicatorWidth}`).replace(/\s/g, ''), [id, indicatorWidth])
  const manyUniqueKey = useCallback((row) => (`${id}-${manyIndicatorsWidth(row)}`).replace(/\s/g, ''), [id, manyIndicatorsWidth])

  const renderValueBorders = useCallback(
    () => (
      <ValueBorders>
        <ValueBorder margin={!(settings[MIN_VALUE] && settings[MIN_VALUE])}>
          <Lang id="menu.min" />:
          {' '}
          {(!settings[MIN_VALUE])
            ? '-'
            : minValueNumber}
          {' '}
          {(!settings[MIN_VALUE])
            ? null
            : units}
          {' '}
          {settings.minCriticalValue ? '(!)' : null}
        </ValueBorder>
        <ValueBorder margin={!(settings[MIN_VALUE] && settings[MIN_VALUE])}>
          <Lang id="menu.max" />:
          {' '}
          {(!settings[MAX_VALUE])
            ? '-'
            : maxValueNumber}
          {' '}
          {(!settings[MAX_VALUE])
            ? null
            : units}
          {' '}
          {settings.maxCriticalValue ? '(!)' : null}
        </ValueBorder>
      </ValueBorders>
    ),
    [units, minValueNumber, maxValueNumber, settings],
  )

  const renderManyValueBorders = useCallback(
    (row) => {
      const minValueByField = minValueNumberByField([`${MIN_VALUE}${row.title}`])
      const maxValueByField = maxValueNumberByField([`${MAX_VALUE}${row.title}`])
      return (
        <ValueBorders>
          <ValueBorder margin={!(minValueByField && maxValueByField)}>
            <Lang id="menu.min" />:
            {' '}
            {(minValueByField && maxValueByField)
              ? minValueByField
              : null}
            {' '}
            {(minValueByField && maxValueByField)
              ? units
              : null}
            {' '}
            {isCriticalManyMinValue(row) ? '(!)' : null}
          </ValueBorder>
          <ValueBorder margin={!(minValueByField && maxValueByField)}>
            <Lang id="menu.max" />:
            {' '}
            {(minValueByField && maxValueByField)
              ? maxValueByField
              : null}
            {' '}
            {(minValueByField && maxValueByField)
              ? units
              : null}
            {' '}
            {isCriticalManyMaxValue(row) ? '(!)' : null}
          </ValueBorder>
        </ValueBorders>
      )
    },
    [
      minValueNumberByField,
      maxValueNumberByField,
      units,
      isCriticalManyMinValue,
      isCriticalManyMaxValue,
    ],
  )

  const renderButtons = useCallback(() => (
    <ControlsRow>
      {/* do not display icon temperary */}
      {/* {chartControll && (
        chartView
          ? (
            <PortalTooltip
              title={(<Lang id="tooltip.close" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  onClick={handleSetChartView}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <MapsIcons.CircleChartIcon />
                </IconContainer>
              )}
            />
          )
          : (
            <PortalTooltip
              title={(<Lang id="tooltip.graph" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  onClick={handleSetChartView}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <MapsIcons.GraphicIcon />
                </IconContainer>
              )}
            />
          )
      )} */}
      {/* do not display icon temperary */}
      {settingsControll
        && (
          <PortalTooltip
            title={(<Lang id="tooltip.settings" />)}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                onClick={handleOpenSettings}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <MapsIcons.SettingsIcon />
              </IconContainer>
            )}
          />
        )}
    </ControlsRow>
  ), [
    // chartView,
    // handleSetChartView,
    handleOpenSettings,
    settingsControll,
    // chartControll,
  ])

  const renderManySettingsButtons = useCallback(() => (
    <ControlsRow>
      {/* do not display icon temperary */}
      {/* {chartControll && (
        chartView
          ? (
            <PortalTooltip
              title={(<Lang id="tooltip.close" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  onClick={handleSetChartView}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <MapsIcons.CircleChartIcon />
                </IconContainer>
              )}
            />
          )
          : (
            <PortalTooltip
              title={(<Lang id="tooltip.graph" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  onClick={handleSetChartView}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <MapsIcons.GraphicIcon />
                </IconContainer>
              )}
            />
          )
      )} */}
      {/* do not display icon temperary */}
      {settingsControll
        && (
          <PortalTooltip
            title={(<Lang id="tooltip.settings" />)}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <IconContainer
                onClick={handleOpenManySettings}
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                <MapsIcons.SettingsIcon />
              </IconContainer>
            )}
          />
        )}
    </ControlsRow>
  ), [
    // chartView,
    // handleSetChartView,
    handleOpenManySettings,
    settingsControll,
    // chartControll,
  ])

  const lineColor = useMemo(
    () => {
      if (settings[MAX_VALUE] && settings[MIN_VALUE]) {
        if (indicatorWidth < 20 || indicatorWidth > 80 || settings[MIN_VALUE] > value) {
          return 'dangerous'
        }
        if (indicatorWidth < 40 && indicatorWidth > 20) {
          return 'warning'
        }
        return false
      }
      if (settings[MAX_CRITICAL_VALUE] || settings[MIN_CRITICAL_VALUE]) {
        if (indicatorWidth > 66.66 || settings[MIN_VALUE] > value) {
          return 'dangerous'
        }
        if (indicatorWidth < 66.66 && indicatorWidth > 33.33) {
          return 'warning'
        }
      }
      return false
    },
    [settings, indicatorWidth, value],
  )

  const manyLineColor = useCallback(
    (row) => {
      const minValueByField = minValueNumberByField([`${MIN_VALUE}${row.title}`])
      if (isCriticalManyMaxValue(row) && isCriticalManyMinValue(row)) {
        if (
          manyIndicatorsWidth(row) < 20
          || manyIndicatorsWidth(row) > 80
          || minValueByField > row.value
        ) {
          return 'dangerous'
        }
        if (manyIndicatorsWidth(row) < 40 && manyIndicatorsWidth(row) > 20) {
          return 'warning'
        }
        if (manyIndicatorsWidth(row) < 80 && manyIndicatorsWidth(row) > 60) {
          return 'warning'
        }
        return false
      }
      if (isCriticalManyMaxValue(row) || isCriticalManyMinValue(row)) {
        if (manyIndicatorsWidth(row) > 66.66 || minValueByField > row.value) {
          return 'dangerous'
        }
        if (manyIndicatorsWidth(row) < 66.66 && manyIndicatorsWidth(row) > 33.33) {
          return 'warning'
        }
      }
      return false
    },
    [
      manyIndicatorsWidth,
      isCriticalManyMaxValue,
      isCriticalManyMinValue,
      minValueNumberByField,
    ],
  )

  const renderSmallView = useCallback(
    () => (
      <IndicatorWrapper
        className={className}
        column
        cursor={(chartControll || settingsControll) ? 'pointer' : 'default'}
      >
        <DescriptionWrapper row pb={10}>
          <Title name>{title}</Title>
          <Value right>
            <span>
              {`${valueNumber} `}
              {units || ''}
            </span>
          </Value>
        </DescriptionWrapper>
        <IndicatorLine>
          <IndicatorLineActive
            uniqueKey={uniqueKey}
            widthValue={indicatorWidth}
            status={status}
            lineColor={lineColor}
          />
        </IndicatorLine>
        <DescriptionWrapper row>
          {renderButtons()}
          {renderValueBorders()}
        </DescriptionWrapper>
      </IndicatorWrapper>
    ),
    [
      className,
      chartControll,
      settingsControll,
      title,
      valueNumber,
      units,
      uniqueKey,
      indicatorWidth,
      status,
      lineColor,
      renderButtons,
      renderValueBorders,
    ],
  )

  const renderArrayView = useCallback(
    () => {
      if (type === 'detailed') {
        return (
          <IndicatorWrapper
            className={className}
            column
          >
            <DescriptionWrapper>
              {data.map((row, i) => {
                if (row.value) {
                  return (
                    <>
                      <DescriptionWrapper center >
                        <Value spaceBetween>
                          <Title manyTitle>
                            {`${title}`}
                            <Lang id="menu.onPhase" />
                            {`${row.title}`}
                          </Title>
                          <span>
                            {`${row.value} `}
                            {units || ''}
                          </span>
                        </Value>
                      </DescriptionWrapper>
                      <IndicatorLine>
                        <IndicatorLineActive
                          uniqueKey={manyUniqueKey(row)}
                          widthValue={manyIndicatorsWidth(row)}
                          lineColor={manyLineColor(row)}
                          status={status}
                        />
                      </IndicatorLine>
                      <DescriptionWrapper row>
                        {(data.length === i + 1)
                          ? renderManySettingsButtons()
                          : null}
                        {renderManyValueBorders(row)}
                      </DescriptionWrapper>
                    </>
                  )
                }
                return null
              })}
            </DescriptionWrapper>
          </IndicatorWrapper>
        )
      }
      return (
        <IndicatorWrapper
          className={className}
          column
        >
          <DescriptionWrapper pb={10}>
            <Title>{title}</Title>
          </DescriptionWrapper>
          <DescriptionWrapper>
            {data.map((row) => {
              return (
                  <Value ws pv>
                    {row.title}
                    <Unit>
                      {units}
                    </Unit>
                    {numberWithSpaces(row.value)}
                  </Value>
              )
            })}
          </DescriptionWrapper>
        </IndicatorWrapper>
      )
    },
    [
      type,
      className,
      title,
      data,
      units,
      manyUniqueKey,
      manyIndicatorsWidth,
      manyLineColor,
      status,
      renderManySettingsButtons,
      renderManyValueBorders,
    ],
  )

  // const renderChartView = useCallback(() => (
  //   <IndicatorWrapper
  //     className={className}
  //     column
  //   >
  //     <DescriptionWrapper row>
  //       <Title>{title}</Title>
  //     </DescriptionWrapper>
  //     <LineChart />
  //     {renderButtons()}
  //   </IndicatorWrapper>
  // ), [className, title, renderButtons])

  const renderSettingsView = useCallback(() => (
    <IndicatorWrapper
      className={className}
      column
    >
      <ObjectWidgetSettings
        onCancel={handleCloseSettings}
        onSubmit={onUpdateSettings}
        initialValues={{
          minCriticalValue: settings.minCriticalValue,
          maxCriticalValue: settings.maxCriticalValue,
          minValue: minValueNumber,
          maxValue: maxValueNumber,
        }}
      />
    </IndicatorWrapper>
  ),
  [
    minValueNumber,
    maxValueNumber,
    className,
    handleCloseSettings,
    onUpdateSettings,
    settings.minCriticalValue,
    settings.maxCriticalValue,
  ])

  const renderManySettingsView = useCallback(() => (
    <IndicatorWrapper
      className={className}
      column
    >
      <ManyObjectsWidgetSettings
        onCancel={handleCloseManySettings}
        onSubmit={onUpdateSettings}
        data={data}
        initialValues={{
          ...settings,
          ...data.reduce((accumulator, row = '') => ({
            ...accumulator,
            [`${MIN_VALUE}${row.title}`]: minValueNumberByField(`${MIN_VALUE}${row.title}`),
            [`${MAX_VALUE}${row.title}`]: maxValueNumberByField(`${MAX_VALUE}${row.title}`),
          }), {}),
        }}
      />
    </IndicatorWrapper>
  ),
  [
    className,
    handleCloseManySettings,
    onUpdateSettings,
    data,
    settings,
    minValueNumberByField,
    maxValueNumberByField,
  ])

  const renderIndicatorView = useCallback(
    () => {
      if (settingsView) {
        return renderSettingsView()
      }
      if (manySettingsView) {
        return renderManySettingsView()
      }
      if (data && data.length) {
        return renderArrayView()
      }
      // if (chartView) {
      //   return renderChartView()
      // }

      return renderSmallView()
    },
    [
      settingsView,
      data,
      // chartView,
      // renderChartView,
      renderSmallView,
      renderArrayView,
      renderSettingsView,
      renderManySettingsView,
      manySettingsView,
    ],
  )

  return renderIndicatorView()
}

StateIndicator.propTypes = {
  id: pt.string.isRequired,
  value: pt.oneOfType([pt.number, pt.string]),
  minValue: pt.oneOfType([pt.number, pt.string]),
  maxValue: pt.oneOfType([pt.number, pt.string]),
  data: pt.arrayOf(pt.shape({
    title: pt.string,
    value: pt.number,
  })),
  units: pt.string,
  title: pt.string.isRequired,
  className: pt.string,
  color: pt.string,
  settingsControll: pt.bool,
  chartControll: pt.bool,
  onUpdateSettings: pt.func,
  settings: pt.shape({
    minValue: pt.oneOfType([pt.number, pt.string]),
    maxValue: pt.oneOfType([pt.number, pt.string]),
  }),
}
StateIndicator.defaultProps = {
  value: 0,
  minValue: 0,
  maxValue: 0,
  data: [],
  units: null,
  className: '',
  color: '',
  settingsControll: true,
  chartControll: true,
  onUpdateSettings: noop,
  settings: {},
}

export default memoWithName(StateIndicator)
