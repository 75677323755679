import React, { useEffect, useState, useMemo, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import set from 'lodash/set'
import get from 'lodash/get'
import useReduxTable from '@/hooks/useReduxTable'
import { ACTION_LOG_TABLE } from '@/store/actions/actionLog'
import { getTableParameters } from '@/store/selectors/actionLog'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import UsersIcons from '@/components/icons/users'
import DutyTable from '@/components/blocks/DutyTable'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import ViewTree from '@/components/blocks/ViewTree'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/actionLog'
import {
  COMPANY,
  GROUP,
  USER,
  ADMIN,
  TENANT,
} from '@/constants/viewTree'
import {
  Main,
  Content,
} from './styles'

const AlarmsManager = ({
  data,
  tableData,
  loading,

  setEventTypes,
  setStatusTypes,
  requestAlarmManagerTable,
  requestAlarmManagerFile,
  intl,
  globalFilters,
  requestActionLogTable,
  tenants,
  admins,
  groups,
  users,
  selectedTenant,
  requestGetAllTenants,
  requestGetTenantGroups,
  requestGetAllUsers,
  requestGetAllAdminTenants,
  loggedInUser,
  requestActivitiLogFile,
  setSelectedElement,
  requestActivitiLogDictinory,
  dictionaries,
  setActivitiLogFilter,
  activitiLogFilterValue,
  dates,
  setDatesRange,
}) => {
  useHtmlTitle(intl.messages['menu.activityLog'])
  const [search, setSearch] = useState(null)
  const [toggledTenantPath, setToggledTenantPath] = useState({})
  const userRoles = useMemo(() => get(loggedInUser, 'roles', {}), [loggedInUser])

  useEffect(() => {
    requestGetAllTenants()
    requestActivitiLogDictinory()
  }, [requestActionLogTable, requestGetAllTenants, requestActivitiLogDictinory])

  useEffect(() => {
    if (tenants.length) {
      setSelectedElement({tenantId: tenants[0].id})
      requestActionLogTable({tenantId: tenants[0].id})
    }
  }, [requestActionLogTable, tenants, setSelectedElement])

  const tableProps = useReduxTable(ACTION_LOG_TABLE, getTableParameters)

  const handleChange = useCallback((value) => {
    setSearch(value)
  }, [setSearch])
  
  const resetSearch = () => {
    setSearch('')
  }

  const typesToElement = useMemo(() => ({
    [GROUP]: groups,
    [ADMIN]: admins,
    [TENANT]: tenants,
    [USER]: users,
  }), [
    admins,
    groups,
    tenants,
    users,
  ])
  const tenantStructure = useMemo(() => {
    if (!tenants.length) {
      return []
    }
    const newStructure = tenants.map((element) => {
      const tenantAdmins = (typesToElement[ADMIN] || {})[element.id] || []
      const tenantGroups = (typesToElement[GROUP] || {})[element.id] || []
      let newTenantGroups = []
      if (Object.keys(tenantGroups).length > 0) {
        newTenantGroups = tenantGroups.map((group) => {
          const groupUsers = group.usersAmount ? (typesToElement[USER] || {})[`${group.tenantId}-${group.id}`] || [] : []

          return {
            ...group,
            children: groupUsers,
          }
        })
      }
      return {
        ...element,
        loading: !((tenantAdmins.length > 0 || newTenantGroups.length > 0)),
        children: [
          ...element.children,
          ...tenantAdmins,
          ...newTenantGroups,
        ],
      }
    })
    return newStructure.map((tenant) => {
      const pathsForTenant = toggledTenantPath[tenant.id] || []
      const updatedTenant = { ...tenant }
      pathsForTenant.map((path) => {
        set(updatedTenant, `${path}toggled.`, true)
        return path
      })
      return updatedTenant
    })
  },
  [
    tenants,
    typesToElement,
    toggledTenantPath,
  ])

  const requestFile = (payload) => {
    requestActivitiLogFile(payload)
  }

  

  const handleSelected = useCallback((node, event) => () => {
    if (event === 'toggle') {
      const tenantId = node.tenantId || node.id
      const nodePath = node.path
      const nodeToggledValue = node.toggled || false
      const newTenantToggledNode = (toggledTenantPath[tenantId] || []).filter(
        (element) => element !== nodePath,
      )
      if (!nodeToggledValue) {
        newTenantToggledNode.push(nodePath)
      }
      setToggledTenantPath({
        ...toggledTenantPath,
        [tenantId]: newTenantToggledNode,
      })
      if (node.type === COMPANY && !node.toggled) {
        requestGetAllAdminTenants(node.id)
        if (!userRoles.isSuperAdmin) {
          requestGetTenantGroups(node.id)
        }
      }
      if (node.type === GROUP && !node.toggled) {
        requestGetAllUsers({ tenantId: node.tenantId, groupId: node.id })
      }
    }
    if (event === 'click') {
      setActivitiLogFilter({
        action: [],
        eventType: [],
        fullName: [],
        appName: [],
      })
      if (node.type === GROUP) {
        setSelectedElement({groupId: node.id})
        requestActionLogTable({groupId: node.id})
        return
      }
      if (node.type === COMPANY) {
        setSelectedElement({tenantId: node.id})
        requestActionLogTable({tenantId: node.id})
      } else {
        setSelectedElement({userId: node.id})
        requestActionLogTable({userId: node.id})
      }
      requestActivitiLogDictinory()
    }
  },[
    toggledTenantPath, 
    requestActionLogTable, 
    setSelectedElement, 
    requestGetAllAdminTenants,
    requestGetAllUsers,
    requestGetTenantGroups,
    userRoles.isSuperAdmin,
    requestActivitiLogDictinory,
    setActivitiLogFilter,
  ])

  const handleFilterColumn = (value, id) => {
    setActivitiLogFilter({
      ...activitiLogFilterValue,
      [id]: value
    })
    requestActivitiLogDictinory()
    requestActionLogTable()
  }

  const renderTree = useMemo(
    () => {
    const newTenants = tenantStructure.map((tenant) => {
      if (tenant.id === selectedTenant.id) {
        const newChildren = tenant.children
          .filter((element) => (element.type && element.name) || element.isFake)
        return {
          ...selectedTenant,
          ...tenant,
          loading: false,
          children: [
            ...newChildren,
          ],
        }
      }
      return tenant
    })
    return (
      <PageSidebar
          userTree
          title={<Lang id="menu.activityLog" />}
          content={(
            <>
              {newTenants.map(((tenant) => (
                <ViewTree
                  key={JSON.stringify(tenant)}
                  treeData={tenant}
                  searchQuery={search}
                  onSelect={handleSelected}
                />
              )))}
              {/* {(type === TENANT) && Object.keys(selectedTenant).length && (
                <ViewTree
                  key={JSON.stringify(selectedTenant)}
                  treeData={selectedTenant}
                  searchQuery={search}
                />
              )} */}
            </>
            )}
          headerContent={(
            <Lang id="usersPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={handleChange}
                  value={search}
                  resetButton
                  resetField={resetSearch}
                />
              )}
            </Lang>
          )}
        />
    )
  },[tenantStructure, handleSelected, search, handleChange, selectedTenant])
  return (
    <Main>
      <Content>
        {renderTree}
        <DutyTable
          {...tableProps}
          withDates
          dates={dates}
          fields={fields}
          getTableData={setDatesRange}
          exportOptions={exportOptions}
          onFileSelect={requestFile}
          data={tableData}
          setEventTypes={setEventTypes}
          setStatusTypes={setStatusTypes}
          requestAlarmManagerTable={requestAlarmManagerTable}
          isDataLoading={loading}
          fieldOptions={dictionaries}
          descriptions={activitiLogFilterValue}
          handleFilterColumn={handleFilterColumn}
          onUpdateData={requestActionLogTable}
        />
      </Content>
    </Main>
  )
}

AlarmsManager.propTypes = {
  data: pt.arrayOf(pt.object),

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsManager.defaultProps = {
  data: [],

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsManager
