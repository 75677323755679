import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getFile from './getFile'
import getActivitiLogDictinory from './getActivitiLogDictinory'



export default function* root() {
  yield all([
    getTable(),
    getFile(),
    getActivitiLogDictinory(),
  ])
}