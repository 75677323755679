import styled from 'styled-components'

export const CellContainer = styled.div`
  padding: 0 5px 0 15px;
  text-align: left;
  display: flex;
  justify-content: space-between

`

export const DiffValue = styled.div`
  display: flex;
  gap: 5px;
`

export const Arrow = styled.div`
  margin-right: 0;
  ${({ transform }) => (transform
    ? 'transform: rotate(0deg);'
    : 'transform: rotate(180deg); margin-top: -3px;')}
`