import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'

import { GET_TOTOAL_RESULTS } from '@/store/actions/inventory'
import { GET_INVENTORY_TOTAL_URL } from '@/constants/apiRoutes'
import { successTotalResults } from '@/store/actions/inventory/getTotalResults'
import  { getDates, getIdIntegrations } from '@/store/selectors/inventory'



function* getTotalResults({payload}) {
  try {
    const {dateFirst: dateFirstFilter, dateSecond: dateSecondFilter} = yield select(getDates)
    const idIntegrations = yield select(getIdIntegrations)
    const dateFirst = new Date(dateFirstFilter)
    const dateSecond = new Date(dateSecondFilter)
    const dateCheckup = new Date('2021-04-19')
    const ids = () => {
      if (dateFirstFilter && dateSecondFilter) {
        if ((dateFirst <= dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: idIntegrations.acceptanceAndInspection,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: idIntegrations.modernizationAndReconstruction,
          }
        }
        if ((dateFirst <= dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateFirstFilter) {
        if ((dateFirst <= dateCheckup)) {
          return { 
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateSecondFilter) {
        if ( (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      return {}
    }
    const response = yield request({
      url: GET_INVENTORY_TOTAL_URL({
        params: {  
          firstIntegrationId: ids().firstIntegrationId,
          secondIntegrationId: ids().secondIntegrationId,        
        },
      }),
      method: 'get',
    })

    yield put(successTotalResults({...response?.data, asuno: 1}))
   
  } catch (error) {
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_TOTOAL_RESULTS.REQUEST, getTotalResults)
}
