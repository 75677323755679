import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getInventory = createSelector(
  getState,
  (state) => get(state, 'inventory', {}),
)

export const getTableParameters = createSelector(
  getInventory,
  (settings) => get(settings, 'table', {}),
)

export const getTableData = createSelector(
  getInventory,
  (settings) => get(settings, 'data', {}),
)

export const getTotalResults = createSelector(
  getInventory,
  (settings) => get(settings, 'totalResults', {}),
)

export const getLoading = createSelector(
  getInventory,
  (settings) => get(settings, 'loading', {}),
)

export const getIdIntegrations = createSelector(
  getInventory,
  (settings) => get(settings, 'idIntegrations', {}),
)

export const getDates = createSelector(
  getInventory,
  (settings) => get(settings, 'dates', {}),
)

