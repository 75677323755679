import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_INVENTORY_TABLE = createAction('@/GET_INVENTORY_TABLE')
export const INVENTORY_TABLE = createTableActions('@/INVENTORY_TABLE')
export const GET_TOTOAL_RESULTS = createAction('@/GET_TOTOAL_RESULTS')

export const SET_ID_INTEGRATIONS = '@/INVENTORY_SET_ID_INTEGRATIONS'
export const SET_DATES_RANGE = createAction('@/INVENTORY_SET_DATES_RANGE')

export const setIdIntergrations = (payload) =>({
  type: SET_ID_INTEGRATIONS,
  payload,
})

export const setDatesRange = (payload) => ({
  type: SET_DATES_RANGE,
  payload,
})

