import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'

export const failureLogFields = [
    {
        id: 'scheduleName',
        name: <Lang id="tableFields.scheduleManager.name" />,
        value: 'ASC',
    },
    {
        id: 'geoZoneName',
        name: <Lang id="tableFields.scheduleManager.geoZone" />,
        value: 'ASC',
    },
    {
        id: 'countAssignedObject',
        name: <Lang id="tableFields.scheduleManager.deviceCount" />,
        value: 'ASC',
        hideSortButtons: true,
    },
    {
        id: 'status',
        name: <Lang id="tableFields.scheduleManager.error" />,
        value: 'ASC',
    },
    {
        id: 'createdDateTime',
        name: <Lang id="tableFields.scheduleManager.originDate" />,
        value: 'ASC',
    },
    {
        id: 'isResolved',
        name: <Lang id="tableFields.scheduleManager.errorStatus" />,
        value: 'ASC',
        customCell: (props) => (props.value ? <Lang id="tableFields.scheduleManager.resolved" /> : <Lang id="tableFields.scheduleManager.notResolved" />),
    },
]

export const activityLogFields = [
    {
        id: 'userName',
        name: <Lang id="tableFields.scheduleManager.user" />,
        value: 'ASC',
    },
    {
        id: 'createDateTime',
        name: <Lang id="tableFields.scheduleManager.actionDateTime" />,
        value: 'ASC',
    },
    {
        id: 'scheduleName',
        name: <Lang id="tableFields.scheduleManager.name" />,
        value: 'ASC',
    },
    {
        id: 'userAction',
        name: <Lang id="tableFields.scheduleManager.action" />,
        value: 'ASC',
        customCell: (props) => (<Lang id={`tableFields.scheduleManager.${props.value}`} />)
    },
    {
        id: 'justificationComment',
        name: <Lang id="tableFields.scheduleManager.comment" />,
        value: 'ASC',
    },
]

export const exportOptions = [
    {
        name: 'EXCEL',
        value: 'xlsx',
        icon: LiveTime.EXELIcon,
    },
    {
        name: 'PDF',
        value: 'pdf',
        icon: LiveTime.PDFIcon,
    },
]

export const errorStatusOptions = [
    {
        name: <Lang id="tableFields.scheduleManager.resolved" />,
        id: 'true',
    },
    {
        name: <Lang id="tableFields.scheduleManager.notResolved" />,
        id: 'false',
    },
]

export const actionOptions = [
    {
        name: <Lang id="tableFields.scheduleManager.CREATE" />,
        title: <Lang id="tableFields.scheduleManager.CREATE" />,
        id: 'CREATE',
        value: 'CREATE',
    },
    {
        name: <Lang id="tableFields.scheduleManager.EDIT" />,
        title: <Lang id="tableFields.scheduleManager.EDIT" />,
        id: 'EDIT',
        value: 'EDIT',
    },
    {
        name: <Lang id="tableFields.scheduleManager.DELETE" />,
        title: <Lang id="tableFields.scheduleManager.DELETE" />,
        id: 'DELETE',
        value: 'DELETE',
    },
]

export const viewOptions = [
    {
        title: <Lang id="tooltip.table" />,
        value: 'table'
    },
    {
        title: <Lang id="tooltip.graph" />,
        value: 'graph'
    },
]

export default {
    failureLogFields,
    viewOptions,
    exportOptions
}
