import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ArrowInventory = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.94 10.2723L8 7.21894L11.06 10.2723L12 9.33228L8 5.33228L4 9.33228L4.94 10.2723Z" fill={fill}/>
    </svg>
    
  )
}

ArrowInventory.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ArrowInventory.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ArrowInventory)
