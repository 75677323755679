import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'

/**
 * Function for creating a simple object with field names
 * from an array of fields configuration
 * @param {Object[]} fields - array of fields configuration
 * @param {string} fields[].id - field name
 * @returns {string[]} Simple object with field names
 */
const getPlainFieldsArray = (fields = []) => fields.map(({ id }) => id)

/**
 * Function for creating default table state
 * @param {string[]|Object[]} fields - array of fields configuration
 * @returns {Object} Default table state
 */
const generateDefaultTableState = (fields = [], defaultSort = {}, requestQuery = '', totalElements, page, perPage) => {
  const isFieldsPlain = !isEmpty(fields) && isString(first(fields))

  const activeFields = isFieldsPlain ? fields : getPlainFieldsArray(fields)
  return ({
    sort: {
      direction: defaultSort.direction || 'DESC',
      field: defaultSort.field || activeFields[0],
    },
    query: requestQuery,
    requestQuery: requestQuery,
    page: page || 1,
    perPage: perPage || 20,
    resultCount: totalElements || 0,
    activeFields,
  })
}

export default generateDefaultTableState
