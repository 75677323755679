import React, { useState, useCallback } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import EditUserFromAdminForm from '@/forms/EditUserFromAdminForm'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import UserFormHeader from '@/components/blocks/Header'
import {
  Container,
} from './styles'

const EditUserFromAdmin = ({
  selectedUser,
  groups,
  requestDeleteUser,
  requestUpdateUserFromAdmin,
  // actionsDisabled,
  userRole,
  isFormModifying,
  closeForm,
  setFormIsModifying,
}) => {
  const editAdminForm = React.createRef()
  const [isDirty, setIsDirty] = useState(false)
  // const [cancelForm, setCancelForm] = useState(false)
  // const [deleteUser, setDeleteUser] = useState(false)
  // const handleCancelForm = useCallback((value) => () => {
  //   setCancelForm(value)
  // }, [setCancelForm])
  // const handleDeleteUser = useCallback((value) => () => {
  //   setDeleteUser(value)
  // }, [setDeleteUser])

  const deleteHandler = () => {
    requestDeleteUser({
      selectedUser,
      id: selectedUser.id,
      tenantId: selectedUser.tenantId,
      groupId: selectedUser.group.id,
    })
  }

  const handleSubmit = useCallback(() => {
    if (isEmpty(editAdminForm.current.state.errors)) {
      requestUpdateUserFromAdmin({
        forms: editAdminForm.current,
        tenantId: selectedUser.tenantId,
        id: selectedUser.id,
        initialBlocked: selectedUser.blocked,
        groupId: get(selectedUser, 'group.id', null),
      })
    }
  }, [editAdminForm, requestUpdateUserFromAdmin, selectedUser])

  // const returnInitialValues = useCallback(() => {
  //   setCancelForm(false)
  //   editAdminForm.current.resetForm()
  // }, [editAdminForm])


  // const handleDebounceSubmit = debounce(() => {
  //   handleSubmit()
  // }, DEBOUNCE_DELAY_SMALL)

  const handleDebounceDelete = debounce(() => {
    deleteHandler()
  }, DEBOUNCE_DELAY_SMALL)


  // const renderCancelTitle = useMemo(() => {
  //   if (cancelForm) {
  //     return (
  //       <>
  //         <Title cancelTitle>
  //           <Lang id="usersPage.formHeader.cancelChangeForm" />
  //         </Title>
  //         <IconContainer disabled={actionsDisabled} type="red" onClick={returnInitialValues}>
  //           <Lang id="usersPage.formHeader.yes" />
  //         </IconContainer>
  //         <IconContainer disabled={actionsDisabled} type="green" onClick={handleCancelForm(false)}>
  //           <Lang id="usersPage.formHeader.no" />
  //         </IconContainer>
  //       </>
  //     )
  //   }
  //   return (
  //     <>
  //       <IconWrapper>
  //         <IconContainer disabled={actionsDisabled} type="green" onClick={handleDebounceSubmit}>
  //           <Icons.OKIcon color="white" />
  //         </IconContainer>
  //       </IconWrapper>
  //       <Title cancelTitle>
  //         <Lang id="usersPage.formHeader.changeForm" />
  //       </Title>
  //       <IconWrapper>
  //         <IconContainer disabled={actionsDisabled} type="red" onClick={handleCancelForm(true)}>
  //           <Icons.CrossIcon color="white" />
  //         </IconContainer>
  //       </IconWrapper>
  //     </>
  //   )
  // }, [cancelForm, handleCancelForm, handleDebounceSubmit, returnInitialValues, actionsDisabled])
  // const renderDeleteTitle = useMemo(() => (
  //   <>
  //     <Title cancelTitle>
  //       <Lang id="usersPage.formHeader.deleteUser" />
  //     </Title>
  //     <IconContainer disabled={actionsDisabled} type="red" onClick={handleDebounceDelete}>
  //       <Lang id="usersPage.formHeader.yes" />
  //     </IconContainer>
  //     <IconContainer disabled={actionsDisabled} type="green" onClick={handleDeleteUser(false)}>
  //       <Lang id="usersPage.formHeader.no" />
  //     </IconContainer>
  //   </>
  // ), [handleDebounceDelete, handleDeleteUser, actionsDisabled])
  // const renderTitle = useMemo(() => {
  //   if (isDirty) {
  //     return renderCancelTitle
  //   }
  //   if (deleteUser) {
  //     return renderDeleteTitle
  //   }
  //   return (
  //     <>
  //       <IconWrapper />
  //       <Title>
  //         <Lang id="usersPage.formHeader.user" />
  //       </Title>
  //       <IconWrapper>
  //         {userRole.isAdmin && (
  //         <PortalTooltip
  //           title={(<Lang id="tooltip.remove" />)}
  //           renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
  //             <IconContainer
  //               disabled={actionsDisabled}
  //               deleteButton
  //               onClick={handleDeleteUser(true)}
  //               ref={wrapperRef}
  //               onMouseEnter={onMouseEnterHandler}
  //               onMouseLeave={onMouseLeaveHandler}
  //             >
  //               <HeaderIcons.DeleteIcon />
  //             </IconContainer>
  //           )}
  //         />
  //         )}
  //       </IconWrapper>
  //     </>
  //   )
  // },
  // [
  //   isDirty,
  //   deleteUser,
  //   handleDeleteUser,
  //   renderCancelTitle,
  //   renderDeleteTitle,
  //   actionsDisabled,
  //   userRole,
  // ])
  return (
    <Container>
      <UserFormHeader
        editTitle={<Lang id="usersPage.formHeader.changingProfile" />}
        editMode={isFormModifying}
        saveForm={handleSubmit}
        closeForm={closeForm}
        title={<Lang id="usersPage.formHeader.user" />}
        setFormIsModifying={setFormIsModifying}
        deleteForm={handleDebounceDelete}
        popupSettings={{
          error: {
            title: <Lang id="usersPage.popup.attention" />,
            message: isFormModifying
              ? <Lang id="usersPage.popup.cancelChangeUser" />
              : (
                <>
                  <Lang id="usersPage.popup.deleteUser" />
                  {`${selectedUser.name}?`}
                </>
              ),
          },
          warning: {
            title: <Lang id="usersPage.popup.impossibleExecute" />,
            message: <Lang id="usersPage.popup.saveUser" />,
          },
        }}
        conditionsForAdding={isDirty}
        withoutEditing={userRole.isUser}
      />

      <EditUserFromAdminForm
        ref={editAdminForm}
        setDirty={setIsDirty}
        formData={selectedUser}
        blockForm={!selectedUser.isMe}
        tenantGroups={groups}
        disableFields
        defaultFields={false}
        canEdit={userRole.isAdmin}
        isFormModifying={isFormModifying}
      />
    </Container>
  )
}
EditUserFromAdmin.propTypes = {
  type: pt.string,
  selectedUser: pt.objectOf(pt.object),
}
EditUserFromAdmin.defaultProps = {
  type: '',
  selectedUser: {},
}

export default EditUserFromAdmin
