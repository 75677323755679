import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 31px);
  display: flex;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const EmptySchedule = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colors.redisign.lightGray};
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.92rem;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.colors.dim};
  ${({ active, theme }) => active && `
    color: ${theme.colors.colors.bluePrimary300};
  `}
`

export const TabHeader = styled.div`
  display: flex;
  height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  padding: 5px 10px;
  ${({ active, theme }) => active && `
    border-bottom: 2px solid ${theme.colors.borders.bluePrimary300};
  `}
  min-width: 103px;
`
