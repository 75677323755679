import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'

import { GET_INVENTORY_TABLE, INVENTORY_TABLE} from '@/store/actions/inventory'
import { GET_INVENTORY_TABLE_URL } from '@/constants/apiRoutes'
import { successInventoryTable, errorInventoryTable } from '@/store/actions/inventory/getTable'
import  { getTableParameters, getDates, getIdIntegrations } from '@/store/selectors/inventory'
import { setGlobalLoading } from '@/store/actions/appSettings'




function* getInventoryTableSaga({payload}) {
  try {
    yield put(setGlobalLoading(true))
    const tableParameters = yield select(getTableParameters)
    const {dateFirst: dateFirstFilter, dateSecond: dateSecondFilter} = yield select(getDates)
    const idIntegrations = yield select(getIdIntegrations)
    const dateFirst = new Date(dateFirstFilter)
    const dateSecond = new Date(dateSecondFilter)
    const dateCheckup = new Date('2021-04-19')
    const ids = () => {
      if (dateFirstFilter && dateSecondFilter) {
        if ((dateFirst <= dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: idIntegrations.acceptanceAndInspection,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: idIntegrations.modernizationAndReconstruction,
          }
        }
        if ((dateFirst <= dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateFirstFilter) {
        if ((dateFirst <= dateCheckup)) {
          return { 
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateSecondFilter) {
        if ( (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      return {}
    }
    const response = yield request({
      url: GET_INVENTORY_TABLE_URL({
        params: {  
          firstIntegrationId: ids().firstIntegrationId,
          secondIntegrationId: ids().secondIntegrationId,        
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchText: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],       
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
        yield put(successInventoryTable({
        data: content.map((el) => {
          return (
            {
              ...el, asunoAhp: 0, shunoAhp: 0, lightFixtureAhp: 0, 
            }
          )
        }),
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    }
    yield put(setGlobalLoading(false))
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorInventoryTable())
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_INVENTORY_TABLE.REQUEST, getInventoryTableSaga)
  yield takeLatest(INVENTORY_TABLE.PAGE, getInventoryTableSaga)
  yield takeLatest(INVENTORY_TABLE.SORT, getInventoryTableSaga)
  yield takeLatest(INVENTORY_TABLE.PER_PAGE, getInventoryTableSaga)
  yield takeLatest(INVENTORY_TABLE.REQUEST_QUERY, getInventoryTableSaga)
}
