import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { withRouter } from 'react-router-dom'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import UsersIcons from '@/components/icons/users'
import YandexMap from '@/components/blocks/YandexMap'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import GeoZoneForm from '@/forms/GeoZoneForm'
import ProjectForm from '@/forms/ProjectForm'
import InstallationForm from '@/forms/InstalationForm'
import { allFilterOption, allFilterOption as INSTALLATION_WIDGET_FILTER_CONFIG } from '@/forms/InstalationForm/config'
import IntegrationForm from '@/forms/IntegrationForm'
import useInstallationParams from '@/hooks/useInstallationParams'
import { REQUEST_STATUSES } from '@/constants/requests'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { MAP_SERVICE } from '@/constants/applications'

import {
  OK,
  WARNING,
  ERROR,
  INFO,
  UNKNOWN,
} from '@/constants/objectStatuses'
import {
  ACTION_TYPE,
  CREATE_TYPE,
  CREATE,
  PUT,
  FILTER,
} from '@/constants/semanticNames'
import { GEOZONE, GROUP, ROOT} from '@/constants/objectTypes'
import Preview from './components/Preview'
import PassportAndCard from './components/PassportAndCard'
import TreeSidebar from './components/TreeSidebar'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  generateAlerts,
  isRenderGeoZoneForm,
  isRenderProjectForm,
  isRenderIntegrationForm,
  allowNewPointCreation,
  newPointWasCreated,
  isCanCreateElement,
  formatGeoZoneCreateValues,
  formatGeoZonePutValues,
  formatProjectCreateValues,
  formatProjectPutValues,
  formatObjectCreateValues,
  formatObjectPutValues,
  formatIntegrationCreateValues,
  formatIntegrationPutValues,
} from './utils'
import {
  Container,
  YandexMapContainer,
  BarContainer,
  MapBarContainer,
  NavContainer,
  AlarmMessageContainer,
  IconContainer,
} from './styles'
import { FormattedMessage as Lang } from 'react-intl'
import Loader from '@/components/blocks/Loader'
import { globalFiltersDefaultValues } from '@/constants/filters'
import {
  CONNECTED_LINES,
  CONNECTED_LINES_OBJECT_TYPES,
  LINE,
  MESH,
  UTILITY_POLE
} from '@/constants/instalationPassport/types'
import { mash, mashDisplayElements } from '@/data/mapTree/mashTreeData'

const Installation = ({
  requestCreateGeoZone,
  requestEditGeoZone,
  requestDeleteGeoZone,
  requestGetIntegrationTypes,

  requestCreateObject,
  requestEditObject,
  requestDeleteObject,

  requestCreateIntegration,
  requestDeleteIntegration,
  requestEditIntegration,

  requestCreateProject,
  requestGetCard,
  requestEditProject,
  requestDeleteProject,
  // requestDeleteProjectCustomField,

  requestVerifyElements,
  requestGetScheme,
  requestDownloadMultipleObject,
  requestGetObjectValidationScheme,
  requestGetInstallationObjectPDFFile,
  requestDownloadMultipleObjectScheme,
  requestDownloadMultipleObjectProgress,
  requestGetObjectConnectedIdentifiers,
  requestGetConnectedLinesCoordinates,

  setCard,
  roots,
  card,
  integrationTypes,
  objectTypes,
  parentElements,
  displayElements,
  validationScheme,
  setMapCenter,
  objectTypesRequestStatus,
  setSelectedElementParams,
  intl,
  setInstallationParams,
  setRequestStatus,
  requestGetPinsAndZonesAndTelemetry,
  successGetPinsAndZonesAndTelemetry,
  userApplications,
  loggedInUser,
  pinNode,
  pinnedNode,
  globalLoading,
  globalFilters,
  displayElementAndTelemetryRequestStatus,
  zones,
  objectConnectedIdentifiers,
  connectedLinesCoordinates,
  mapLayer,
  setSearchTree,
  isRussianBorders,
}) => {
  useHtmlTitle(intl.messages['menu.installation'])
  const formRef = useRef(null)
  const selectedNode = useMemo(() => ({ ...get(card, 'data', {}) }), [card])
  const [formValues, setFormValues] = useInstallationParams()
  const [alertConfig, setAlertConfig] = useState({})
  const [newPoints, setNewPoints] = useState([])
  const [newPointsHash, setNewPointsHash] = useState(null)
  const [isCardOpen, setIsCardOpen] = useState(false)
  const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0]?.settings
  const userRoles = useMemo(() => get(loggedInUser, 'roles', {}), [loggedInUser])
  const [selectedZoneIdentifier, setSelectedZoneIdentifier] = useState(null)
  const [blockedRequestPins, setBlockedRequestPins] = useState(false)
  const [isChildren, setIsChildren] = useState(true)

  useEffect(
    () => () => setInstallationParams({ FILTER: INSTALLATION_WIDGET_FILTER_CONFIG }),
    [setInstallationParams],
  )

  useEffect(() => {
    if(!isEqual(globalFiltersDefaultValues, globalFilters)) {
      successGetPinsAndZonesAndTelemetry({
        displayElements: [],
      })
    }
    setSelectedZoneIdentifier(null)
  }, [globalFilters, successGetPinsAndZonesAndTelemetry])

  const getInstallationObjectPDF = useCallback(() => {
    requestGetInstallationObjectPDFFile({
      id: formValues.id,
      name: get(card, 'data.name', null),
      fields: get(card, 'data.objectElementFieldsDto', []),
    })
  }, [card, formValues.id, requestGetInstallationObjectPDFFile])

  const createElementType = useMemo(() => {
    if (formValues[CREATE_TYPE] === GEOZONE) {
      return 'city'
    }
    if (newPoints.length !== 0) {
      return get(newPoints, '[0].type', UNKNOWN)
    }
    return UNKNOWN
  }, [formValues, newPoints])

  const changeCreateObjectType = useCallback((type) => {
    setNewPoints([{
      ...get(newPoints, '[0]', {}),
      type,
    }])
  }, [newPoints])

  useEffect(() => {
    requestGetIntegrationTypes()
  }, [requestGetIntegrationTypes])

  const canCreateElement = useMemo(
    () => isCanCreateElement(formValues),
    [formValues],
  )

  const displayIntegrationFilter = useMemo(
    () => !(formValues[ACTION_TYPE] === CREATE || formValues[ACTION_TYPE] === PUT),
    [formValues],
  )

  const allowGetCoorsFromMap = useMemo(
    () => allowNewPointCreation(formValues, alertConfig, newPoints),
    [formValues, newPoints, alertConfig],
  )

  const isPointWasCreated = useMemo(() => newPointWasCreated(formValues, alertConfig, newPoints),
    [formValues, newPoints, alertConfig])

  useEffect(() => {
    // temporarily add
    if (!allowGetCoorsFromMap) {
      if (newPoints.length !== 0) {
        setNewPoints([])
      }
      return noop
    }
    // temporarily add
    const lat = get(formRef, 'current.state.values.LOCATION.LATITUDE', null)
      || get(formRef, `current.state.values.${CREATE_REGION_NAMES.LATITUDE}`, null)
    const lon = get(formRef, 'current.state.values.LOCATION.LONGITUDE', null)
      || get(formRef, `current.state.values.${CREATE_REGION_NAMES.LONGITUDE}`, null)
    const radius = get(formRef, 'current.state.values.radius', null)
    if (
      (allowGetCoorsFromMap || newPoints.length !== 0)
      && formRef && formRef.current
      && lat && lon
    ) {
      const newPoint = {
        ...get(newPoints, '[0]', {}),
        location: [lat, lon],
        lat,
        lon,
        radius: radius,
        status: INFO,
        state: INFO,
        type: createElementType,
        count: 0,
        statistic: {
          [OK]: 0,
          [WARNING]: 0,
          [INFO]: 0,
          [ERROR]: 0,
        },
      }
      if (!isEqual(newPoint, newPoints[0] || {})) {
        setNewPoints([newPoint])
      }
    }
    return noop
  }, [newPoints, setNewPoints, formRef, createElementType, newPointsHash, allowGetCoorsFromMap])

  const startEditElement = useCallback(() => {
    setFormValues({
      ...formValues,
      [ACTION_TYPE]: PUT,
      editMode: true
    })
  }, [formValues, setFormValues])

  const filterValue = useMemo(() => formValues[FILTER], [formValues])

  useEffect(() => {
    const {
      id, type, path, parentTreeId, parentGeoZoneId, rootId, editMode, createMode
    } = formValues
    if (id && type && roots.length && mapLayer !== MESH) {
      requestGetCard({
        id,
        type,
        path,
        parentTreeId,
        parentGeoZoneId,
        rootId,
        editMode,
        createMode,
        objectStates: formValues[FILTER].length === allFilterOption.length ? null : formValues[FILTER]
      })
      if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === pinnedNode.type)) {
        requestGetObjectConnectedIdentifiers({
          parentId: pinnedNode.type === LINE || pinnedNode.type === UTILITY_POLE ? pinnedNode.parentId : id,
          objectStates: formValues[FILTER].length === allFilterOption.length ? null : formValues[FILTER]
        })
        if (mapLayer === CONNECTED_LINES) {
          requestGetConnectedLinesCoordinates({
            id,
            parentId: pinnedNode.parentId
          })
        }
      }
    }
  }, [
    formValues,
    requestGetCard,
    setCard,
    roots,
    requestGetObjectConnectedIdentifiers,
    requestGetConnectedLinesCoordinates,
    mapLayer
  ])

  useEffect(() => {
    const { editMode, createMode } = formValues
    if (
      displayElementAndTelemetryRequestStatus === REQUEST_STATUSES.NOT_REQUESTED &&
      pinnedNode.id &&
      pinnedNode.type !== ROOT &&
      !editMode &&
      !createMode &&
      roots.length &&
      !blockedRequestPins &&
      mapLayer !== MESH
      ) {
      requestGetPinsAndZonesAndTelemetry({
        parentTreeId: pinnedNode.path[1]|| pinnedNode.id,
        objectStates: formValues[FILTER].length === allFilterOption.length ? null : formValues[FILTER]
      })
      setSelectedZoneIdentifier(pinnedNode.type === GEOZONE ? null : pinnedNode.path[1] || pinnedNode.id)
    }
  },[formValues,
    requestGetPinsAndZonesAndTelemetry,
    pinnedNode,
    roots,
    globalFilters,
    selectedNode,
    displayElementAndTelemetryRequestStatus,
    blockedRequestPins,
    mapLayer
    ])

  const setSelectedNodeInUrl = useCallback((node) => {
    const { type: prevType, parentGeoZoneId: prevParentGeoZoneId, id: prevElementId } = formValues
    const {
      id,
      systemType,
      type,
      rootGeoZone,
      parentGeoZoneId,
      parentTreeId,
      treeNodeType,
      geoZoneId,
      parentId
    } = node
    const localParentGeoZoneId = type === GEOZONE ? id : parentGeoZoneId
    if (prevElementId === id) {
      return null
    }
    setFormValues({
      id,
      path: node?.original?.options?.path,
      type: treeNodeType || type || systemType,
      rootId: rootGeoZone,
      parentGeoZoneId: localParentGeoZoneId || geoZoneId,
      parentTreeId: parentTreeId || parentId,
      FILTER: filterValue,
    })
    setSelectedElementParams({})
    if (prevType !== GEOZONE && prevParentGeoZoneId !== localParentGeoZoneId) {
      setRequestStatus({ displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED })
    }
    return null
  }, [
    setSelectedElementParams,
    setRequestStatus,
    setFormValues,
    filterValue,
    formValues,
  ])

  useEffect(() => {
    if (!isCardOpen && pinnedNode.id) {
      setSelectedNodeInUrl(pinnedNode)
      setIsCardOpen(true)
    }
  },[setSelectedNodeInUrl, pinnedNode, isCardOpen])

  const onPinClickHandler = (node) => {
    if (mapLayer !== MESH) {
      const { type: prevType, id: prevElementId } = formValues
      const {
        id, elementType, rootGeoZone, parentGeoZoneId, parentTreeId,
      } = node
      const localParentGeoZoneId = elementType === GEOZONE ? id : parentGeoZoneId
      const { parentGeoZoneId: prevParentGeozonId } = formValues
      if (prevElementId === id) {
        return null
      }
      setSearchTree('')
      pinNode(node)
      setBlockedRequestPins(true)
      setSelectedElementParams({})
      setIsChildren(true)
      setFormValues({
        id,
        path: node?.original?.options?.path,
        type: elementType,
        rootId: rootGeoZone,
        parentGeoZoneId: localParentGeoZoneId,
        parentTreeId,
        FILTER: filterValue,
      })   
      if (prevType !== GEOZONE && prevParentGeozonId !== localParentGeoZoneId) {
        setRequestStatus({ displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED })
      }
      return null
    } else {
    }
  }

  const closeAlertHandler = useCallback(() => {
    const { FILTER } = formValues
    setFormValues({ FILTER })
    setAlertConfig({})
    setNewPoints([])
  }, [setFormValues, setAlertConfig, formValues, setNewPoints])

  const closeForm = useCallback(() => {
    const { FILTER, type } = formValues
    setFormValues({ FILTER, type })
    setNewPoints([])
  }, [setFormValues, formValues, setNewPoints])

  const closeAlert = useCallback(() => {
    setAlertConfig({})
    setNewPoints([])
  }, [setAlertConfig, setNewPoints])

  const cancelCreation = useCallback(() => {
    setAlertConfig({})
    const {
      id, type, rootId, parentGeoZoneId, parentTreeId, path, FILTER,
    } = formValues
    setFormValues({
      id,
      type,
      rootId,
      parentGeoZoneId,
      parentTreeId,
      path,
      FILTER,
    })
    setNewPoints([])
  }, [formValues, setFormValues, setNewPoints])

  const deleteRootGeoZoneHandler = useCallback(() => {
    setAlertConfig({})
    pinNode({})
    requestDeleteGeoZone({
      id: selectedNode.id,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteGeoZone, setAlertConfig, setFormValues, formValues, pinNode])

  const deleteGeoZoneHandler = useCallback(() => {
    setAlertConfig({})
    pinNode({})
    requestDeleteGeoZone({
      id: selectedNode.id,
      parentId: selectedNode.parentId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteGeoZone, setAlertConfig, setFormValues, formValues, pinNode])

  const deleteProjectHandler = useCallback(() => {
    setAlertConfig({})
    pinNode({})
    requestDeleteProject({
      id: selectedNode.id,
      parentId: selectedNode.parentId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteProject, setAlertConfig, setFormValues, formValues, pinNode])

  const deleteObjectHandler = useCallback(() => {
    setAlertConfig({})
    pinNode({})
    requestDeleteObject({
      id: selectedNode.id,
      parentId: selectedNode.parentId,
      objectState: selectedNode.objectState,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteObject, setAlertConfig, setFormValues, formValues, pinNode])

  const deleteIntegrationHandler = useCallback(() => {
    setAlertConfig({})
    pinNode({})
    requestDeleteIntegration({
      id: selectedNode.id,
      parentId: selectedNode.parentId,
      setUrlFormValues: setFormValues,
      formValues,
    })
  }, [selectedNode, requestDeleteIntegration, setAlertConfig, setFormValues, formValues, pinNode])

  const {
    deleteElementHandler,
    createEditElementInvalidValuesHandler,
    parentCreateError,
    cancelCreationEdit,
    verifyElement,
    editElement
  } = useMemo(() => generateAlerts(
    formValues[CREATE_TYPE] || formValues.type,
    formValues[ACTION_TYPE],
    setAlertConfig,
    card,
    closeAlert,
    closeAlertHandler,
    deleteRootGeoZoneHandler,
    deleteGeoZoneHandler,
    deleteProjectHandler,
    deleteObjectHandler,
    deleteIntegrationHandler,
    cancelCreation,
    requestVerifyElements,
    requestEditObject,
    intl
  ), [
    formValues,
    setAlertConfig,
    card,
    closeAlert,
    closeAlertHandler,
    deleteRootGeoZoneHandler,
    deleteGeoZoneHandler,
    deleteProjectHandler,
    deleteObjectHandler,
    deleteIntegrationHandler,
    cancelCreation,
    requestVerifyElements,
    requestEditObject,
    intl
  ])

  const handleClickZone = useCallback((node) => {
    pinNode(node)
    setSelectedZoneIdentifier(node.id)
    setSelectedNodeInUrl(node)
    setBlockedRequestPins(false)
  },[pinNode, setSelectedNodeInUrl])

  const viewTreeOnSelect = useCallback((node, actionType) => {
    if (actionType === 'click' && mapLayer !== MESH) {
      setSelectedNodeInUrl(node)
      pinNode(node)
      setBlockedRequestPins(!((pinnedNode && pinnedNode.id && pinnedNode.path[1] !== node.path[1]) || !selectedZoneIdentifier) && node.type !== GEOZONE)
      setIsChildren(!!node.childrenCount)
    } 
    return null
  }, [setSelectedNodeInUrl, pinNode, pinnedNode, selectedZoneIdentifier, mapLayer])

  const renderSideBar = useCallback(() => (
    <TreeSidebar
        onSelect={viewTreeOnSelect}
        title={<Lang id="installation.title" />}
        hidden={canCreateElement} 
        selectedNode={pinnedNode}
        isChildren={isChildren}
        isMashMode={mapLayer === MESH}
        mapLayer={mapLayer}
        iconDropdown
        />
  ), [viewTreeOnSelect, canCreateElement, pinnedNode, isChildren, mapLayer])

  const renderSideBarPreview = useCallback(() => (
    formValues.id && formValues.type && mapLayer !== MESH
      ? (
        <NavContainer>
          <Preview
            intl={intl}
            selectedNode={{
              ...card.data || {},
            }}
            onClose={closeForm}
            onDelete={deleteElementHandler}
            onEdit={startEditElement}
            userRoles={userRoles}
          />
        </NavContainer>
      )
      : null
  ), [
    deleteElementHandler,
    startEditElement,
    formValues,
    closeForm,
    card,
    intl,
    userRoles,
    mapLayer
  ])

  const renderGeoZoneForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let ghostMode = true
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatGeoZoneCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateGeoZone
      title = <Lang id={"installation.addGeoZoneTitle"}/>
      ghostMode = !isPointWasCreated
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatGeoZonePutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditGeoZone
      title = <Lang id={"installation.editGeoZoneTitle"}/>
      ghostMode = false
      edit = true
    }
    return (
      <NavContainer br>
        <GeoZoneForm
          ref={formRef}
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          onCancel={cancelCreationEdit}
          submit={onSubmitLocal}
          title={title}
          ghostMode={ghostMode}
          formValues={formValues}
          // getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
          setNewPointsHash={setNewPointsHash}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    createEditElementInvalidValuesHandler,
    formValues,
    isPointWasCreated,
    parentElements,
    requestCreateGeoZone,
    requestEditGeoZone,
    setFormValues,
  ])

  const renderProjectForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatProjectCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateProject
      title = <Lang id={"installation.addProjectTitle"}/>
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatProjectPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditProject
      title = <Lang id={"installation.editProjectTitle"}/>
      edit = true
    }
    return (
      <NavContainer br>
        <ProjectForm
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          onCancel={cancelCreationEdit}
          submit={onSubmitLocal}
          // deleteCustomField={requestDeleteProjectCustomField}
          title={title}
          formValues={formValues}
          // getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    createEditElementInvalidValuesHandler,
    formValues,
    parentElements,
    requestCreateProject,
    requestEditProject,
    setFormValues,
    // requestDeleteProjectCustomField
  ])

  const renderObjectForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let ghostMode = true
    let edit = false
    let create = false
    let objectNewPoint = []
    let setObjectNewPoint = noop
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatObjectCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateObject
      title = <Lang id={"installation.addObjectTitle"}/>
      ghostMode = !isPointWasCreated
      objectNewPoint = newPoints
      setObjectNewPoint = setNewPoints
      create = true
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatObjectPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = card.data.installationType === GROUP ? editElement : verifyElement
      title = <Lang id={"installation.editObjectTitle"}/>
      ghostMode = false
      edit = true
    }
    return (
      <NavContainer br>
        <PassportAndCard
          intl={intl}
          ref={formRef}
          objectTypes={objectTypes}
          data={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          formValues={formValues}
          submit={onSubmitLocal}
          onCancel={cancelCreationEdit}
          title={title}
          ghostMode={ghostMode}
          edit={edit}
          create={create}
          validationScheme={validationScheme}
          requestGetObjectValidationScheme={requestGetObjectValidationScheme}
          requestGetScheme={requestGetScheme}
          changeCreateObjectType={changeCreateObjectType}
          getInstallationObjectPDF={getInstallationObjectPDF}
          objectNewPoint={objectNewPoint}
          setObjectNewPoint={setObjectNewPoint}
          objectTypesRequestStatus={objectTypesRequestStatus}
          setAlertConfig={setAlertConfig}
          setNewPointsHash={setNewPointsHash}
          objectConnectedIdentifiers={objectConnectedIdentifiers}
        />
      </NavContainer>
    )
  }, [
    cancelCreationEdit,
    card,
    changeCreateObjectType,
    createEditElementInvalidValuesHandler,
    formValues,
    getInstallationObjectPDF,
    intl,
    isPointWasCreated,
    newPoints,
    objectTypes,
    objectTypesRequestStatus,
    requestCreateObject,
    requestGetObjectValidationScheme,
    requestGetScheme,
    setFormValues,
    validationScheme,
    verifyElement,
    editElement,
    objectConnectedIdentifiers,
  ])

  const renderIntegrationForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedNode = {}
    let title = ''
    let edit = false
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedNode = formatIntegrationCreateValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestCreateIntegration
      title = <Lang id={"installation.addIntegrationTitle"}/>
    }
    if (formValues[ACTION_TYPE] === PUT) {
      localSelectedNode = formatIntegrationPutValues(card)
      onSubmitErrorLocal = createEditElementInvalidValuesHandler
      onSubmitLocal = requestEditIntegration
      title = <Lang id={"installation.editIntegrationTitle"}/>
      edit = true
    }
    return (
      <NavContainer br>
        <IntegrationForm
          integrationTypes={integrationTypes}
          selectedNode={localSelectedNode}
          submitError={onSubmitErrorLocal}
          setUrlFormValues={setFormValues}
          submit={onSubmitLocal}
          onCancel={cancelCreationEdit}
          title={title}
          formValues={formValues}
          // getParentZoneOptions={requestGetTenantParents}
          parentElements={parentElements}
          edit={edit}
        />
      </NavContainer>
    )
  }, [
    formValues,
    integrationTypes,
    setFormValues,
    cancelCreationEdit,
    parentElements,
    card,
    createEditElementInvalidValuesHandler,
    requestCreateIntegration,
    requestEditIntegration,
  ])

  const renderForm = useCallback(() => {
    if (isRenderGeoZoneForm(formValues)) {
      return renderGeoZoneForm()
    }
    if (isRenderProjectForm(formValues)) {
      return renderProjectForm()
    }
    if (isRenderIntegrationForm(formValues)) {
      return renderIntegrationForm()
    }
    return renderObjectForm()
  }, [
    formValues,
    renderGeoZoneForm,
    renderProjectForm,
    renderIntegrationForm,
    renderObjectForm,
  ])

  const renderLeftPart = useCallback(() => (canCreateElement
    ? renderForm()
    : renderSideBarPreview()),
  [canCreateElement, renderForm, renderSideBarPreview])

  return (
    <>
      {!isEmpty(alertConfig) && (
        <GlobalPopup content={<GlobalPopupContent {...alertConfig} shoodBeFixByArtem />} />
      )}
      {globalLoading && (
        <GlobalPopup content={<Loader center />} />
      )}
      <Container>
        {renderSideBar()}
        {renderLeftPart()}
        <MapBarContainer>
          <BarContainer empty={!displayIntegrationFilter}>
            {displayIntegrationFilter && (
              <InstallationForm
                setFormValues={setFormValues}
                isCreateBlock={selectedNode.isCreateBlock}
                parentCreateError={parentCreateError}
                requestDownloadMultipleObject={requestDownloadMultipleObject}
                requestDownloadMultipleObjectScheme={requestDownloadMultipleObjectScheme}
                requestDownloadMultipleObjectProgress={requestDownloadMultipleObjectProgress}
                alertConfig={alertConfig}
                userRoles={userRoles}
                parentId={formValues.id}
                intl={intl}
                setBlockedRequestPins={setBlockedRequestPins}
              />
            )}
          </BarContainer>
          <YandexMapContainer isAlarm={allowGetCoorsFromMap && !isPointWasCreated}>
            {allowGetCoorsFromMap && !isPointWasCreated
              && (
                <AlarmMessageContainer>
                  <Lang id={"installation.cancelAdd"}/>
                  <IconContainer onClick={cancelCreation}>
                    <UsersIcons.CrossIcon />
                  </IconContainer>
                </AlarmMessageContainer>
              )}
            <YandexMap
              formRef={formRef}
              globalZoneId={selectedZoneIdentifier}
              handleClickZone={handleClickZone}
              pins={mapLayer === MESH ? mashDisplayElements : displayElements}
              pinnedNode={pinnedNode}
              zones={zones}
              createPoint={allowGetCoorsFromMap}
              createElementType={createElementType}
              handleOpenPassport={onPinClickHandler}
              isDisplayElements
              pointsArray={newPoints}
              mash={mapLayer === MESH ? mash : []}
              yandexKey={yandexKey}
              roots={roots}
              objectStateFilters={formValues.FILTER}
              connectedLinesCoordinates={connectedLinesCoordinates}
              isMashMode={mapLayer === MESH}
              isLinesMode={mapLayer === CONNECTED_LINES}
              isRussianBorders={isRussianBorders}
            />
          </YandexMapContainer>
        </MapBarContainer>
      </Container>
    </>
  )
}

Installation.defaultProps = {
  requestCreateGeoZone: noop,
  requestEditGeoZone: noop,
  requestDeleteGeoZone: noop,
  requestGetIntegrationTypes: noop,
  requestGetObjectTypes: noop,
  requestCreateObject: noop,
  requestEditObject: noop,
  requestDeleteObject: noop,
  requestCreateIntegration: noop,
  requestDeleteIntegration: noop,
  requestEditIntegration: noop,

  requestGetRootElements: noop,
  requestCreateProject: noop,
  requestGetCard: noop,
  requestEditProject: noop,
  requestDeleteProject: noop,
  requestDeleteProjectCustomField: noop,
  requestVerifyElements: noop,
  requestGetTenantParents: noop,
  requestGetScheme: noop,
  setCard: noop,
  requestDownloadMultipleObject: noop,
  requestGetObjectValidationScheme: noop,
  requestGetInstallationObjectPDFFile: noop,
  requestDownloadMultipleObjectScheme: noop,
  setSelectedElementParams: noop,
  setMapCenter: noop,
  setMapZoom: noop,
  setInstallationParams: noop,
  setRequestStatus: noop,
  requestGetPinsAndZonesAndTelemetry: noop,

  validationScheme: {},
  integrationTypes: [],
  objectTypes: [],
  parentElements: [],
  roots: [],
  card: {
    data: {},
    requestStatus: null,
  },
  displayElements: [],
  mapCenter: [],
  mapZoom: null,
  intl: {},
  objectTypesRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  rootsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  userApplications: []
}

Installation.propTypes = {
  requestEditIntegration: pt.func,
  requestDeleteIntegration: pt.func,
  requestCreateIntegration: pt.func,
  requestDeleteObject: pt.func,
  requestEditObject: pt.func,
  requestCreateObject: pt.func,
  requestGetObjectTypes: pt.func,
  requestCreateGeoZone: pt.func,
  requestGetIntegrationTypes: pt.func,
  requestEditGeoZone: pt.func,
  requestDeleteGeoZone: pt.func,
  requestVerifyElements: pt.func,
  requestGetRootElements: pt.func,
  requestCreateProject: pt.func,
  requestGetCard: pt.func,
  requestEditProject: pt.func,
  requestDeleteProject: pt.func,
  requestDeleteProjectCustomField: pt.func,
  requestGetTenantParents: pt.func,
  requestGetScheme: pt.func,
  setSelectedElementParams: pt.func,
  setCard: pt.func,
  requestDownloadMultipleObject: pt.func,
  requestGetObjectValidationScheme: pt.func,
  requestGetInstallationObjectPDFFile: pt.func,
  requestDownloadMultipleObjectScheme: pt.func,
  setInstallationParams: pt.func,
  setRequestStatus: pt.func,
  requestGetPinsAndZonesAndTelemetry: pt.func,
  setMapCenter: pt.func,
  setMapZoom: pt.func,
  intl: pt.objectOf(pt.object),
  roots: pt.arrayOf(pt.object),
  parentElements: pt.arrayOf(pt.object),
  integrationTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    title: pt.element,
  })),
  objectTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    title: pt.element,
  })),
  card: pt.shape({
    data: pt.object,
    requestStatus: pt.string,
  }),
  displayElements: pt.arrayOf(pt.shape({
    id: pt.string,
    name: pt.string,
    location: pt.arrayOf(pt.number),
  })),
  validationScheme: pt.objectOf(pt.object),
  mapCenter: pt.arrayOf(pt.number),
  mapZoom: pt.number,
  objectTypesRequestStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  displayElementAndTelemetryRequestStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  rootsStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.PENDING,
    REQUEST_STATUSES.IDLE,
    REQUEST_STATUSES.ERROR,
  ]),
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
}

export default React.memo(withRouter(Installation))
