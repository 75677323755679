import React from 'react'

import ArrowInventory from '@/components/icons/maps/ArrowInventory'


import {
  CellContainer,
  DiffValue,
  Arrow,
} from './styles'


const InventoryCell = ({ value, row, diff }) => {
  const originalValue = row?.original || {}
  const diffValue = originalValue[diff]

  return (
    <CellContainer>
      {value}
      {(diffValue !== 0) && diffValue &&  (
        <DiffValue>
          <Arrow transform={diffValue > 0}>
            <ArrowInventory color={diffValue > 0 ? 'rgb(144, 199, 55)' : 'rgb(248, 61, 20)'}/>
          </Arrow>
          {Math.abs(diffValue)}
        </DiffValue>
      )}
    </CellContainer>
  )
}

export default InventoryCell