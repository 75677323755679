import { GET_INVENTORY_TABLE } from './index'

export const requestInventoryTable = (payload) => ({
  type: GET_INVENTORY_TABLE.REQUEST,
  payload,
})

export const successInventoryTable = (payload) => ({
  type: GET_INVENTORY_TABLE.SUCCESS,
  payload,
})

export const errorInventoryTable = (payload) => ({
  type: GET_INVENTORY_TABLE.ERROR,
  payload,
})