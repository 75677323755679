import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_ACTION_LOG_TABLE,
  GET_ACTION_LOG_FILE,
  ACTION_LOG_TABLE,

  SET_SELECTED_ELEMENT,
  SET_EVETS_DATA,
  GET_ACTIVITI_LOG_DICTINORY,
  SET_ACTIVITI_LOG_FILTER,
  SET_DATES_RANGE,
} from '@/store/actions/actionLog'
import { fields } from '@/constants/tablesConfig/actionLog'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  startDate: null,
  endDate: null,
  selector: 'all',
  eventStatus: ['all'],
  selectedElement: {},
  loading: false,
  dictionaryTable: {},
  activitiLogFilterValue: {
    action: [],
    eventType: [],
    fullName: [],
    appName: [],
  },
  dates: {
    start: null,
    end: null,
  },
  table: generateDefaultTableState(fields, {field: 'eventTime'}),
}

export const reducer = handleActions(
  {
    [SET_EVETS_DATA]: (state, { payload: { eventsCount, eventData } }) => ({
      ...state,
      eventsCount,
      eventData,
    }),
    [SET_SELECTED_ELEMENT]: (state, { payload }) => ({
      ...state,
      selectedElement: payload,
    }),
    [GET_ACTION_LOG_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_ACTION_LOG_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      loading: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_ACTION_LOG_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      data: [],
      loading: false,
    }),
    [GET_ACTION_LOG_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_ACTION_LOG_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_ACTION_LOG_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
    [GET_ACTIVITI_LOG_DICTINORY.SUCCESS]: (state, { payload }) => ({
      ...state,
      dictionaryTable: payload,
    }),
    [SET_ACTIVITI_LOG_FILTER]: (state, { payload }) => ({
      ...state,
      activitiLogFilterValue: payload,
    }),
    [SET_DATES_RANGE]: (state, { payload }) => ({
      ...state,
      dates: payload,
    }),
    ...generateDefaultTableReducer(ACTION_LOG_TABLE),
  },
  initialState,
)

export default reducer
