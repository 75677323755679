import { GET_TOTOAL_RESULTS } from './index'

export const requestTotalResults = (payload) => ({
  type: GET_TOTOAL_RESULTS.REQUEST,
  payload,
})

export const successTotalResults = (payload) => ({
  type: GET_TOTOAL_RESULTS.SUCCESS,
  payload,
})

export const errorTotalResults = (payload) => ({
  type: GET_TOTOAL_RESULTS.ERROR,
  payload,
})