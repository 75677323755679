import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getTotalResults from './getTotalResults'




export default function* root() {
  yield all([
    getTable(),
    getTotalResults(),
  ])
}