import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_ACTION_LOG_TABLE = createAction('@/GET_ACTION_LOG_TABLE')
export const GET_ACTION_LOG_FILE = createAction('@/GET_ACTION_LOG_FILE')
export const GET_ALARMS_COUNT = createAction('@/GET_ALARMS_COUNT')
export const ACTION_LOG_TABLE = createTableActions('@/ACTION_LOG_TABLE')
export const GET_ACTIVITI_LOG_DICTINORY = createAction('@/GET_ACTIVITI_LOG_DICTINORY')
export const SET_ACTIVITI_LOG_FILTER = '@/SET_ACTIVITI_LOG_FILTER'
export const SET_DATES_RANGE = '@/ACTIVITI_LOG_SET_DATES_RANGE'



export const SET_SELECTED_ELEMENT = '@/ACTION_LOG_SET_SELECTED_ELEMENT'
export const SET_EVETS_DATA = '@/ACTION_LOG_SET_EVETS_DATA'

export const setEventsData = (payload) => ({
  type: SET_EVETS_DATA,
  payload,
})
export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})

export const requestActivitiLogDictinory = (payload) => ({
  type: GET_ACTIVITI_LOG_DICTINORY.REQUEST,
  payload,
})

export const successActivitiLogDictinory = (payload) => ({
  type: GET_ACTIVITI_LOG_DICTINORY.SUCCESS,
  payload,
})

export const setActivitiLogFilter = (payload) => ({
  type: SET_ACTIVITI_LOG_FILTER,
  payload,
})

export const setDatesRange = (payload) => ({
  type: SET_DATES_RANGE,
  payload,
})



